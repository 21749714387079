<template>
  <div class="scheme-contain">
    <div class="food-date">
      <Row>
        <Col :lg="{span: 1, offset: 9}" :md="{span: 2, offset: 8}"><span class="text_in">日期：</span></Col>
        <Col :lg="{span: 8}" :md="{span: 8}">
          <Date-picker type="date" :options="options" placeholder="选择日期" style="width: 295px" v-model="foodDate" @on-change="changeFoodDate" v-if="!foodId"></Date-picker>
          <Date-picker type="date" disabled placeholder="选择日期" style="width: 295px" v-model="foodDate" v-else></Date-picker>
        </Col>
      </Row>
    </div>
    <div class="food-list-contain">
      <ul class="tabs-nav clearfix">
        <li><router-link :to="{name: 'FoodRecordAdd', params: {type_id: 0}}">早餐</router-link></li>
        <li><router-link :to="{name: 'FoodRecordAdd', params: {type_id: 1}}">中餐</router-link></li>
        <li><router-link :to="{name: 'FoodRecordAdd', params: {type_id: 2}}">晚餐</router-link></li>
        <li><router-link :to="{name: 'FoodRecordAdd', params: {type_id: 3}}">加餐</router-link></li>
      </ul>
    </div>
    <div class="">
      <div class="food-list">
        <Row :gutter="55">
          <Col :lg="{span: 4}" :md="{span: 8}" v-for="(food, index) in foodNameList" :key="food.id">
            <div class="food-box">
              <img :src="food.photo" onerror="this.src='@/assets/img/imate.png'" alt="" width="100%" height="123px">
              <div class="food-type">
                <Checkbox @on-change="changeFoodStatus" v-model="food.status" data-index="index">{{ food.name }}</Checkbox>
              </div>
              <div class="food-enery">
                <Row>
                  <Col :lg="{span: 11}" :md="{span: 24}"><span class="text_in">{{ food.weight_one }}</span></Col>
                  <Col :lg="{span: 9}" :md="{span: 12}"><Input :data-index="index" @on-change="changeFoodNum"  v-model.number="food.number">{{ food.number }}</Input></Col>
                  <Col :lg="{span: 3, offset: 1}" :md="{span: 11, offset: 1}"><span class="text_in">份</span></Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div class="total-enery" v-show="handleTotal">
        <h2>{{ foodRecord.curTypeName }}能量：<em class="total-text">{{ foodRecord.totalEnergy }}kcal</em></h2>
      </div>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave">保存</Button>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import dayjs from 'dayjs';

export default {
	data() {
		return {
			foodNameList: [],
			foodId: '',
			foodDate: '',
			foodList: '',
			foodTypeData: {
				0: '早餐',
				1: '中餐',
				2: '晚餐',
				3: '加餐',
			},
			foodRecord: {
				// foodDate: '',
				num: '',
				protein: '',
				recommend: '',
				fat: '',
				totalEnergy: 0,
				curTypeName: '早餐',
			},
			total: 20,
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		foodTypeId() {
			return this.$route.params.type_id;
		},
		handleTotal() {
			return this.foodRecord.totalEnergy > 0;
		},
		nowDate() {
			let d = new Date();
			return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
		},
	},
	created() {
		this.initData();
		this.searchFoodDateil();
		// console.log(this.foodId)
	},

	// 如果与初始化数据放在一起，可能初始化响应慢导致数据被覆盖，需加入延时，或等待初始完成之后在进行更新数据
	// mounted() {
	//   setTimeout(this.searchFoodDateil(), 1000)
	// },

	methods: {
		getFoodId() {
			let foodId = sessionStorage.getItem('food_id');

			return foodId ? sessionStorage.getItem('food_id') : this.foodId;
		},
		getFoodDate() {
			let createTime = sessionStorage.getItem('foodDate');

			return createTime ? sessionStorage.getItem('foodDate') : this.foodDate;
		},
		// 初始化基本信息
		initData() {
			this.foodId = this.getFoodId();
			this.foodDate = this.getFoodDate() || this.nowDate;
			// this.foodTypeId = this.foodTypeId
			// this.getFoodList()
			this.foodRecord.curTypeName = this.foodTypeData[this.foodTypeId];
		},

		// 获取食物列表
		getFoodList() {
			MemberService.listFood().then((data) => {
				// console.log('listFood ===' + JSON.stringify(data))
				this.foodNameList = data;
				this.foodRecord.totalEnergy = 0;
				this.foodNameList.forEach((item) => {
					if (item.name === '豆浆') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '鲜奶') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '酸奶') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '饮料') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '啤酒') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '葡萄酒') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '白酒') {
						item.weight_one = `${item.weight_one}ml`;
					} else if (item.name === '红酒') {
						item.weight_one = `${item.weight_one}ml`;
					} else {
						item.weight_one = `${item.weight_one}g`;
					}
				});
			});
		},

		// 成员，时间，类型 获取已存在的食物记录
		searchFoodDateil() {
			// this.getFoodList()
			if (this.foodDate) {
				let req = {
					member_id: this.memberId,
					time: this.foodDate,
					type: this.foodTypeId,
				};
				MemberService.queryFoodDetailData(req).then((data) => {
					// console.log('data ===' + JSON.stringify(data))
					this.foodNameList = data;
					this.foodNameList.forEach((item) => {
						if (item.name === '豆浆') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '鲜奶') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '酸奶') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '饮料') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '啤酒') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '葡萄酒') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '白酒') {
							item.weight_one = `${item.weight_one}ml`;
						} else if (item.name === '红酒') {
							item.weight_one = `${item.weight_one}ml`;
						} else {
							item.weight_one = `${item.weight_one}g`;
						}
					});
					this.handleTotalEnergy();
				});
			}
		},
		// 计算总能量
		handleTotalEnergy() {
			// let foodDataList = this.foodNameList.filter(data => data.status && data.number)
			// let totalNumber = 0.0
			// foodDataList.forEach(item => {
			//     totalNumber += item.number * item.cal
			// })
			// this.foodRecord.totalEnergy = totalNumber
			// console.log('foodNameList == ' + JSON.stringify(this.foodNameList))
			this.foodRecord.totalEnergy = this.foodNameList
				.filter((data) => data.status && data.number && data.can_eat !== 0)
				.map(
					(data) =>
						(data.number * data.cal * parseInt(data.weight_one) * Number(data.can_eat)) / 10000,
				)
				.reduce((p, c) => {
					return c + p;
				}, 0)
				.toFixed(2);
		},
		// 时间改变
		changeFoodDate(date) {
			this.foodDate = date;
			// 在获取数量和状态填充
			this.searchFoodDateil();
		},
		changeFoodStatus() {
			this.handleTotalEnergy();
		},

		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},

		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},

		changeFoodNum(event) {
			if (!this.isPositiveNumber(event.target.value)) {
				this.$Message.warning('请输入数字');
			} else {
				this.handleTotalEnergy();
			}
		},

		handleBack() {
			this.$router.push({ name: 'FoodTable' });
			// this.$router.go(-1)
		},

		handleSave() {
			let foodDate = this.foodDate;
			if (!foodDate) {
				this.$Message.warning('请选择时间');
				return;
			}

			if (!this.handleTotal) {
				this.$Message.warning('您还没有选择食物');
				return;
			}

			let foodList = this.foodNameList.filter((data) => data.status && data.number);
			let foodStr = [];
			foodList.forEach((item) => {
				foodStr.push({
					id: item.id,
					number: item.number,
				});
			});

			let req = {
				food_str: JSON.stringify(foodStr),
				member_id: this.memberId,
				type: this.foodTypeId,
				time: dayjs(foodDate).format('YYYY-MM-DD'),
				total: this.foodRecord.totalEnergy,
			};
			MemberService.addFood(req).then(() => {
				this.$Message.warning(this.foodTypeData[this.foodTypeId] + '保存成功');
			});
		},
	},
	watch: {
		$route() {
			this.initData();
			this.searchFoodDateil();
		},
		// '$route': 'initData'
	},
};
</script>
<style lang="css" scoped>
.food-list-contain {
  margin-top: 15px;
  text-align: center;
}
.tabs-nav {
  display: inline-block;
}
.tabs-nav > li {
  float: left;
  height: 45px;
  line-height: 45px;
  margin-right: 40px;
}
.tabs-nav > li > a {
  padding: 0 15px;
  display: block;
  color: #666;
}
.tabs-nav .active {
  border-bottom: 2px solid #ff8c11;
  color: #ff8c11;
}
</style>
